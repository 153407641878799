button:focus {
  outline: 0;
}

a {
  text-decoration: underline;
}

.text-4xl {
  font-family: 'Inter';
}

.footer {
  background-color: #3e3022;
}

p {
  font-size: 16px;
  text-align: left;
}

img {
  width: 100%;
}

ul.mode {
  list-style: none;
}

span.vertical-line {
  height: 20px;
  padding: 1px;
  margin-left: 7px;
  margin-right: 7px;
  background-color: #b3b6b7;
}

.page-content {
  max-width: 720px;
  margin: auto;
  line-height: 1.6;
}

.nav-tabs .nav-link {
  color: #ba61ff;
  border-width: 1px;
  border-color: #f3f3f3;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  background-color: #ba61ff;
  color: #fff;
  border-color: #ba61ff;
}

.tab-content {
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 0px 0px 4px 4px;
}

.accordion-container {
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
}

.accordion-header {
  background-color: #ba61ff;
  color: #fff;
  cursor: pointer;
  padding: 18px;
  border-radius: 10px;
  margin-bottom: 5px;
}

.accordion-header:focus {
  outline: none;
}

.accordion-body {
  background-color: #f3f3f3;
  padding: 18px;
  border-radius: 0 0 10px 10px;
}

.accordion-body p {
  font-size: 18px;
}

.accordion-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px;
  justify-content: space-between;
}

.accordion-title {
  font-size: 18px;
}

.accordion-icon::before {
  content: '＋';
  font-size: 22px;
  font-weight: bold;
}

.accordion-header.active .accordion-icon::before {
  content: '－';
  font-size: 22px;
  font-weight: bold;
}

.container {
  transform-style: preserve-3d;
}

.container .box {
  position: relative;
  width: calc(42% - 10px);
  height: 220px;
  margin: 20px;
  transform-style: preserve-3d;
  perspective: 1000px;
  cursor: pointer;
}

.container .box .body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: 0.9s ease;
}

.container .box .body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.container .box .body img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container .box .body .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #333;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
}

.container .box:hover .body {
  transform: rotateY(180deg);
}

.container .box .body .content div {
  transform-style: preserve-3d;
  padding: 20px;
  background: linear-gradient(45deg, #9747ff, #ba61ff);
  transform: translateZ(100px);
}

.container .box .body .content div h3 {
  letter-spacing: 1px;
}

.block-list__item {
  margin-bottom: 3rem;
  position: relative;
}

.block-list__item:last-child {
  margin-bottom: 0;
}

.block-list__animated-item {
  margin-bottom: 3rem;
}

.block-list__animated-item:last-child {
  margin-bottom: 0;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
  animation-timing-function: ease-in-out;
}

.block-list__item_dot {
  margin-left: 2.5rem;
  margin-bottom: 3rem;
  position: relative;
}

.block-list__item_dot:last-child {
  margin-bottom: 0;
}

.block-list__content.hidden {
  overflow: hidden;
  visibility: hidden;
  white-space: nowrap;
}

.block-list__content {
  padding-left: 5.5rem;
  width: 100%;
}

.block-list__content.hidden {
  overflow: hidden;
  visibility: hidden;
  white-space: nowrap;
}

.block-list__content__left {
  left: 0;
  position: absolute;
  top: 0;
  width: 4rem;
}

.block-list__content p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 3.4rem;
}

.block-list__content p:last-child {
  margin-bottom: 0;
}

.visually-hidden-always,
.visually-hidden:not(:focus):not(:active) {
  clip: rect(0, 0, 0, 0) !important;
  border: none !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.block-list__number {
  background-color: #ba61ff;
  border-radius: 50%;
  font-size: 18px;
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.block-list__number_dot {
  background-color: #ba61ff;
  border-radius: 50%;
  height: 0.8rem;
  width: 0.8rem;
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  left: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.block-impact {
  padding-bottom: 0px;
  padding-top: 0px;
  background-color: #f3f3f3;
}

.block-impact__quote:before {
  background: #ba61ff;
  content: '';
  height: 0.2rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 7.6rem;
}

.block-impact__quote p {
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

.block-impact__quote {
  margin: 0;
  position: relative;
  padding-top: 10px;
}

.block-impact__quote em {
  font-size: 1.8rem;
  font-weight: 200;
}

ol.carousel-indicators {
  position: absolute;
  bottom: 2rem;
  margin: 0;
  left: 0;
  right: 0;
  width: auto;
}

ol.carousel-indicators li,
ol.carousel-indicators li.active {
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  border: 0px;
  background: transparent;
  color: black;
}

ol.carousel-indicators li {
  color: gray;
  font-size: 16px;
  text-align: center;
  text-indent: unset;
}

ol.carousel-indicators li.active {
  background: transparent;
  background-color: transparent;
  border: 2px solid #ba61ff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.carousel-box {
  background-color: #f3f3f3;
  padding: 2rem;
  width: 100%;
  border-radius: 10px;
}

.carousel-control-prev {
  left: -2rem;
}

.carousel-control-next {
  right: -2rem;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.process-arrow {
  align-items: center;
  background-color: #313537;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  height: 3.5rem;
  justify-content: center;
  position: absolute;
  top: 10rem;
  width: 3.5rem;
  z-index: 3;
  padding: 1rem;
}

.process-arrow--left svg {
  transform: rotate(90deg) translateY(0.2rem);
}
.process-arrow--right svg {
  transform: rotate(-90deg) translateY(0.1rem);
}

.process-card__inner {
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 0.2rem 2.4rem 0 rgba(0, 0, 0, 0.03);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 2rem;
  min-height: 24rem;
  position: relative;
  width: 100%;
  top: -1.5rem;
  z-index: 0;
  border-radius: 10px;
}

.process-card__number p {
  font-size: 20px;
}
.process-card__number {
  border-radius: 10px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  z-index: 1;
}

.process-card__number p {
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.05rem;
  margin: 0;
}

.brand--background {
  background-color: #ba61ff;
}

.process-card__box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mc-question {
  padding-left: 10%;
  padding-right: 10%;
}

.mc-answers {
  padding-left: 10%;
  padding-right: 10%;
}

.mc-divider-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mc-divider {
  background-color: #f3f3f3;
  width: 80%;
  height: 2px;
  margin: 0;
}

.mc-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 0.4rem;
}

.mc-radio[disabled] {
  opacity: 1;
  filter: alpha(opacity=100);
}

.mc-label {
  margin: 0;
  padding-left: 3rem;
}

.mc-item:hover {
  background-color: #f3f3f3;
}

.mc-item-selected-correct {
  border: #ba61ff solid 2px;
}

.mc-item-selected-incorrect {
  border: #000000 solid 2px;
}

.mc-result-container {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

.mc-result-box {
  text-align: center;
  background-color: #f3f3f3;
  padding: 16px;
}

.mc-result-message {
  font-size: 18px;
  font-weight: bold;
}

.mc-result-answer {
  font-size: 18px;
  text-align: center;
  color: #aaa;
}

.mc-button-try-again {
  background-color: transparent;
  border: 0px;
  color: #000000;
  font-weight: bolder;
  font-size: 12px;
}

.mc-button-try-again:hover {
  color: #ba61ff;
}

input[type='radio'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  border: 4px solid #fff;
  border-radius: 50%;
  outline: 1px solid rgb(154, 154, 154);
  pointer-events: none;
}

input[type='radio']:checked {
  background-color: #ba61ff;
}

input[type='checkbox'] {
  accent-color: #ba61ff;
}

.ms-checkbox {
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  pointer-events: none;
}

.btn-submit {
  color: #fff;
  background-color: #ba61ff;
  padding: 10px 60px;
  display: inline-block;
  border: none;
  margin: 4px 2px;
  border-radius: 32px;
}

.btn-submit:disabled {
  background-color: rgb(182, 182, 182);
}

.quiz-card__feedback-icon {
  border: 0.2rem solid #cacbcb;
  border-radius: 50%;
  display: none;
  height: 4rem;
  margin: 0 auto;
  opacity: 1;
  text-align: center;
  width: 4rem;
  line-height: 4.6rem;
}

.quiz-card__feedback-icon-correct {
  color: #ba61ff;
}

.quiz-card__feedback-icon [class*='icon-'] {
  font-size: 2rem;
}

.noOutline {
  outline: none;
}

.quote-remark p {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  font-size: 1.8rem;
  text-align: center;
}

.quote-animation {
  animation-duration: 0.75s;
  opacity: 1;
  animation-delay: 0s;
}

.image-and-text-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image-and-text-box > * {
  padding: 10px;
}

.image-and-text-spacer {
  padding: 10px;
}

.video-box {
  text-align: center;
}

.video-iframe {
  width: 100%;
  height: 400px;
}

.list-box {
  padding-bottom: 30px;
  padding-top: 0px;
}

.footer-desc {
  font-size: 18px;
}

.quiz-match {
  display: flex;
  justify-content: center;
  margin-left: -30px;
  transition: 0.3s;
}

.quiz-match ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.quiz-match ul li {
  padding: 10px;
}

.quiz-match-list-feedback {
  padding: 0;
  margin: 0;
}

.quiz-match-list-left,
.quiz-match-list-right {
  width: 44%;
}

.quiz-match-list-right {
  transform: translateX(20px);
}

.quiz-match-content {
  padding: 0px;
}

.draggable {
  cursor: grab;
  touch-action: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ghostClass {
  cursor: grabbing;
}

.chosenClass {
  cursor: grabbing;
  opacity: 0.6;
}

.dragClass {
  opacity: 1;
}

.quiz-match__item-arrow,
.quiz-match__item-tail {
  height: 100%;
  width: 100%;
  fill: #ffffff;
  stroke-width: 1px;
  stroke: var(--color-caption-border, #ddd);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.quiz-match__item-arrow > rect {
  fill: #a8a8a8;
  stroke: #a8a8a8;
}

.quiz-match__item-sortable {
  fill: #76767c;
}

.quiz-match__item:hover > div > svg > rect {
  fill: #000;
}

.quiz-match__item {
  position: relative;
}

.quiz-match__item,
.quiz-match__item-feedback {
  /* height: 83px; */
  margin-top: 20px;
  margin-bottom: 20px;
}

.quiz-match__item-wrapper {
  width: 100%;
  height: 100%;
}

.quiz-match__item-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;
}

.quiz-match__item-content span {
  padding-left: 10px;
  padding-right: 10px;
}

.quiz-match__item-selected {
  transform: translateX(60px);
}

.quiz-match__item-selected > div > svg > rect {
  fill: #000;
}

.quiz-match__item-feedback {
  --fa-primary-color: var(--accent-contrast-color, #fff);
  --fa-secondary-color: var(--color-accent);
  --fa-secondary-opacity: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 1;
  position: relative;
  transform: translateX(50px);
}

.quiz-match__item-feedback-tick,
.quiz-match__item-feedback-bubble {
  height: 20px;
  width: 20px;
  position: absolute;
  transition: opacity 0.3s;
}

.quiz-match__item-feedback-bubble {
  align-items: center;
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 12px;
  line-height: 1;
}

.hidden {
  display: none;
}

.invisible {
  opacity: 0;
}

.button-next .icon,
.button-prev .icon {
  font-size: 1rem;
  transition: color 0.3s;
  vertical-align: -10%;
}

.button-next,
.button-prev {
  color: #707070;
  cursor: pointer;
  display: inline-block;
  transition: color 0.3s;
  appearance: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.button-next:hover,
.button-prev:hover {
  opacity: 0.8;
}

.popover-body {
  padding: 0;
}

.popover {
  max-width: 40%;
}

@media only screen and (max-width: 768px) {
  div p {
    padding: 0;
  }
  span.vertical-line {
    margin-left: 3px;
    margin-right: 3px;
  }
  .header-container {
    margin-bottom: 20px;
  }
  .header-title {
    font-size: 20px;
  }
  .header-description,
  .header-subheading {
    margin-bottom: 14px;
    margin-top: 14px;
    font-size: 16px;
  }
  .page-content {
    line-height: 1.4;
  }
  .block-list__content {
    padding-left: 4rem;
  }
  .block-list__content p {
    line-height: 1.4;
    margin-bottom: 2rem;
  }
  .subheading {
    padding-left: 10px;
    padding-right: 10px;
  }
  .description {
    padding-left: 10px;
    padding-right: 10px;
  }
  .remark {
    padding-left: 10px;
    padding-right: 10px;
  }
  .subheading {
    font-size: 26px;
  }
  .accordion-container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .space {
    display: none;
  }
  .list-box {
    padding-bottom: 0px;
  }
  ul.mode {
    padding-left: 10px;
  }
}
