.progress-bar {
  display: flex;
  --icon-size: 27px;
  --bar-width: 510px;

  --bar-height: 2px;
}
@media only screen and (max-width: 540px) {
  .progress-bar {
    --bar-width: 300px;
  }
}

.start-end {
  width: var(--bar-width);
  /* background-color: aqua; */
  display: flex;
  flex-direction: row;
  align-items: center; /* Vertically center the content */
}
.start-end > div:first-child,
.start-end > div:last-child {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */

  width: var(--icon-size);
  height: var(--icon-size);
  color: white;
  font-weight: bold;
}

.start-end > div:nth-child(2) {
  flex-grow: 1;
}

.progress-bar {
  position: relative;
  width: var(--bar-width);
  height: var(--icon-size);
}
.invisible-track {
  position: absolute;
  width: calc(var(--bar-width) - var(--icon-size));
  height: var(--bar-height);
  transform: translate(calc(var(--icon-size) / 2), calc(var(--icon-size) / 2));
  z-index: 10;
}
.indicator {
  position: relative;
  width: var(--icon-size);
  height: var(--icon-size);
  left: var(--progress);
  transform: translate(-50%, -60%);
  display: var(--display);
}
.space {
  /* height: 2px; */
  /* background-color: #9747FF; */
  display: block;
}
.track-background {
  position: absolute;
  width: calc(var(--bar-width) - var(--icon-size) - var(--icon-size));
  height: var(--bar-height);
  background-color: #d5d5d5;
  transform: translate(var(--icon-size), 0%);
  align-self: center;
}
.track-foreground {
  position: relative;
  width: var(--progress);
  height: var(--bar-height);
  background-color: #9747ff;
  /* transform: translate(30px, 14px); */
}
