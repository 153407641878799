@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

.mentem-button,
.mentem-headline {
  font-family: 'Inter';
}

input[type='checkbox'] {
  min-width: 20px;
  min-height: 20px;
}
