.shade-container {
  position: absolute;
  z-index: 1;
  height: 120%;
  right: 0px;
  min-width: 100%;
  top: -10%;
}
.foreground-container {
  position: relative;
  z-index: 10;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 5%;
}
@media only screen and (max-width: 768px) {
  .foreground-container {
    width: 55%;
  }
}

.header-title {
  font-weight: bold;
  font-size: 36px;
}
.header-description,
.header-subheading {
  margin-bottom: 25px;
  margin-top: 25px;
  font-size: 26px;
}
@media only screen and (max-width: 768px) {
  .header-title {
    font-size: 25px;
  }
  .header-description {
    font-size: 16px;
  }
}

.header-container {
  font-family: 'Inter';
  margin-bottom: 20px;
  overflow: hidden;
}

.hero-image {
  position: relative;
  height: 100%;
  min-height: 306px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.header-content {
  height: 306px;
  display: flex;
  justify-items: center;
  justify-content: flex-end;
}
